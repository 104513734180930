<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="copyrights"
    :loading="loading"
  >
    <template #top>
      <VToolbar flat>
        <VBtn
          icon
          @click="$router.go(-1)"
        >
          <VIcon>fal fa-chevron-left</VIcon>
        </VBtn>
        <VToolbarTitle>Copyrights</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'CopyrightTable',

  props: {
    copyrights: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Название',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Preroll',
          align: 'left',
          sortable: true,
          value: 'prerollUrl',
        },
        {
          text: 'Postroll',
          align: 'left',
          sortable: true,
          value: 'postrollUrl',
        },
        {
          text: 'Watermark',
          align: 'left',
          sortable: true,
          value: 'watermarkUrl',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
};
</script>
