<template>
  <CopyrightTable
    :copyrights="copyrights"
    :loading="loading"
  >
    <template #topActions>
      <VBtn
        color="primary"
        :to="{
          name : Names.R_CONTENT_STORAGE_COPYRIGHT_CREATE
        }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новый copyright
      </VBtn>

      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить copyright
          </VCardTitle>

          <VCardText>
            Copyright <span class="red--text">{{ copyright.name }}</span> будет удален! <br>
            Действительно хотите удалить copyright?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <VBtn
              text
              @click="handleCancel"
            >
              Нет
            </VBtn>

            <VBtn
              color="red darken-1"
              text
              @click="handleDelete"
            >
              Да
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_CONTENT_STORAGE_COPYRIGHT_VIEW,
              params : {
                copyrightId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click.stop="deleteCopyright(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>
    </template>
  </CopyrightTable>
</template>

<script>
import CopyrightTable from './CopyrightTable.vue';

export default {
  name: 'CopyrightTableContainer',

  components: {
    CopyrightTable,
  },

  inject: ['Names'],

  data() {
    return {
      copyrights: [],
      copyright: {},
      pagination: {
        offset: 0,
        limit: 0,
      },
      loading: false,
      confirmDialog: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { pagination } = this;
        const data = { pagination };

        const { copyrights } = await this.$di.api.ContentStorage.copyrightIndex({ data });

        this.copyrights = copyrights;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleDelete() {
      try {
        this.loading = true;

        const { id } = this.copyright;
        const data = { id };

        await this.$di.api.ContentStorage.copyrightDelete({ data });

        this.resetCopyrightEdit();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
    },

    resetCopyrightEdit() {
      this.confirmDialog = false;
      this.copyright = {};
    },

    handleCancel() {
      this.resetCopyrightEdit();
    },

    deleteCopyright(item) {
      this.confirmDialog = true;
      this.copyright = { ...item };
    },
  },
};
</script>
